import gql from "graphql-tag";

export const GET_RESPONSE_DISPOSITION = gql`
    query GetResponseDisposition($where: String){
        LDPConfigQueryGroup {
            ResponseDisposition(limit:9999, where: $where) {
                CreatedDate
                HttpStatusCode
                IsSuccess
                ModifiedDate
                ResponseDispositionId
                ResponseDispositionName
                UserId
            }
        }
    }
`;  